// ? HTML - HEAD
// This formula is to insert content into <head> element

// Select the <head></head> section:
const head = document.querySelector("head");

// Insert content to <head></head>:
head.insertAdjacentHTML(
  "beforebegin",
  `
   <!-- Copyright © 2024 S-EP Freelance. All rights reserved.

  This website and its content, including but not limited to text, images, code, and design, 
  are the exclusive property of S-EP Freelance. Unauthorized use, reproduction, or distribution 
  of this material is strictly prohibited. 

  Licensed users may modify and distribute this code within the terms of their licensing agreement.
  Any changes to the original code must be clearly documented.

  For inquiries, please contact S-EP Freelance at besteronline@hotmail.com.

  Generated on: July 18, 2024 --> 
`
);

// ? HTML - HEAD  ----------END!
